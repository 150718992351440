const LocationData = [
    {
        City: "Anaheim",
        State: "CA",
        Id: "chimneysweepanaheim.org",
        Url: "https://www.chimneysweepanaheim.org",
        Phone: "951-499-5984"
    },
    {
        City: "Beverly Hills",
        State: "CA",
        Id: "chimneysweepbeverlyhills.org",
        Url: "https://www.chimneysweepbeverlyhills.org",
        Phone: "213-523-3574"
    },
    {
        City: "Calabasas",
        State: "CA",
        Id: "chimneysweepcalabasas.org",
        Url: "https://www.chimneysweepcalabasas.org",
        Phone: "747-347-3440"
    },
    {
        City: "Corona",
        State: "CA",
        Id: "chimneysweepcorona.org",
        Url: "https://www.chimneysweepcorona.org",
        Phone: "951-567-7827"
    },
    {
        City: "Riverside",
        State: "CA",
        Id: "chimneysweepthousandoaks.org",
        Url: "https://www.chimneysweepthousandoaks.org",
        Phone: "805-386-9954"
    },
    {
        City: "Glendale",
        State: "CA",
        Id: "chimneysweepglendale.org",
        Url: "https://www.chimneysweepglendale.org",
        Phone: "747-349-8762"
    },
    {
        City: "Long Beach",
        State: "CA",
        Id: "chimneysweeplongbeach.org",
        Url: "https://www.chimneysweeplongbeach.org",
        Phone: "562-534-9071"
    },
    {
        City: "Moreno Valley",
        State: "CA",
        Id: "chimneysweepmorenovalley.org",
        Url: "https://www.chimneysweepmorenovalley.org",
        Phone: "951-456-4365"
    },
    {
        City: "Oxnard",
        State: "CA",
        Id: "chimneysweepoxnard.org",
        Url: "https://www.chimneysweepoxnard.org",
        Phone: "805-323-9786"
    },
    {
        City: "Pasadena",
        State: "CA",
        Id: "chimneysweeppasadena.org",
        Url: "https://www.chimneysweeppasadena.org",
        Phone: "213-325-6357"
    },
    {
        City: "Pomona",
        State: "CA",
        Id: "chimneysweeppomona.org",
        Url: "https://www.chimneysweeppomona.org",
        Phone: "951-330-3128"
    },
    {
        City: "Rancho Cucamonga",
        State: "CA",
        Id: "chimneysweepranchocucamonga.org",
        Url: "https://www.chimneysweepranchocucamonga.org",
        Phone: "951-800-3479"
    },
    {
        City: "Redlands",
        State: "CA",
        Id: "chimneysweepredlands.org",
        Url: "https://www.chimneysweepredlands.org",
        Phone: "951-612-5839"
    },
    {
        City: "Rialto",
        State: "CA",
        Id: "chimneysweeprialto.org",
        Url: "https://www.chimneysweeprialto.org",
        Phone: "951-644-1458"
    },
    {
        City: "Thousand Oaks",
        State: "CA",
        Id: "chimneysweepriverside.org",
        Url: "https://www.chimneysweepriverside.org",
        Phone: "951-944-3120"
    },
    {
        City: "San Bernardino",
        State: "CA",
        Id: "chimneysweepsanbernardino.org",
        Url: "https://www.chimneysweepsanbernardino.org",
        Phone: "760-253-1869"
    },
    {
        City: "Santa Ana",
        State: "CA",
        Id: "chimneysweepsantaana.org",
        Url: "https://www.chimneysweepsantaana.org",
        Phone: "949-996-0028"
    },
    {
        City: "Temecula",
        State: "CA",
        Id: "chimneysweeptemecula.org",
        Url: "https://www.chimneysweeptemecula.org",
        Phone: "951-585-5914"
    },
    {
        City: "Thousand Oaks",
        State: "CA",
        Id: "chimneysweepthousandoaks.org",
        Url: "https://www.chimneysweepthousandoaks.org",
        Phone: "805-386-9954"
    },
    {
        City: "Torrance",
        State: "CA",
        Id: "chimneysweeptorrance.org",
        Url: "https://www.chimneysweeptorrance.org",
        Phone: "424-367-1252"
    },
    {
        City: "Ventura",
        State: "CA",
        Id: "chimneysweepventura.org",
        Url: "https://www.chimneysweepventura.org",
        Phone: "805-954-9097"
    },
]
export default LocationData;
